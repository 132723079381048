export default (data) => {
  return(
    {
      builderId: data._id ? data._id : "5fb2a5f3ea262c2e14e462ad",
      typeId: data.user ? "user" : "office",
      officeId: data.user ? data.user : data.office,
      primaryColor: data.primaryColor ? data.primaryColor : "black",
      address: data.address ? data.address : "Calle Las Acacias s/n Villa Alemana, Valparaíso",
      email: data.email ? data.email : "contacto@nuvispropiedades.cl ",
      facebook: data.facebook ? data.facebook : "https://web.facebook.com/Nuvispropiedades",
      twitter: data.twitter ? data.twitter : "https://twitter.com",
      instagram: data.instagram ? data.instagram : "https://www.instagram.com/nuvispropiedades/",
      favicon: data.favicon ? data.favicon : require("../images/icon.png"),      
      lat: data.lat ? data.lat : "-33.410167",
      lng: data.lng ? data.lng : "-70.655265",
      logo: data.logo ? data.logo : require("../images/logo-light.png"),
      logoDark: data.logoDark ? data.logoDark : require("../images/logo-dark.png"),
      movil: data.movil ? data.movil : "+56 9 9513 4747",
      phone: data.phone ? data.phone : "+56 9 9513 4747",
      footerText: data.footerText ? data.footerText : "Nueva Vista Gestión Inmobiliaria",
      home:{
        hero: {
          background: data.home && data.home.hero && data.home.hero.background ? data.home.hero.background : require("../images/template-home-hero-background.jpg"),
          title: data.home && data.home.hero && data.home.hero.title ? data.home.hero.title : "Tenemos propiedades <br /> exclusivas pensadas para tí"
        },        
        properties: {
          title: data.home && data.home.properties && data.home.properties.title ? data.home.properties.title : "Contamos con una selección exclusiva de propiedades.",
          maxProperties: data.home && data.home.properties && data.home.properties.maxProperties ? data.home.properties.maxProperties : 9,
          footer: data.home && data.home.properties && data.home.properties.footer ? data.home.properties.footer : "Estas son solo algunas de las propiedades que tenemos para ofrecerte",
          buttonText: data.home && data.home.properties && data.home.properties.buttonText ? data.home.properties.buttonText : "Ver más"
        },        
        about: {
          banner: {
            image: data.home && data.home.about && data.home.about.banner && data.home.about.banner.image ? data.home.about.banner.image : require("../images/template-home-about-hero-background.jpg"),
            title: data.home && data.home.about && data.home.about.banner && data.home.about.banner.title ? data.home.about.banner.title : "Nueva Vista Gestión Inmobiliaria",
            subTitle: data.home && data.home.about && data.home.about.banner && data.home.about.banner.subTitle ? data.home.about.banner.subTitle : "En Nueva Vista Gestión Inmobiliaria nos orientamos principalmente a prestar el Servicio de Compraventa, Arriendos y Administraciones de Propiedades, dentro de la V Región. Principalmente en Quilpué y Villa Alemana. Nuestro objetivo es brindar una experiencia de calidad y confianza a través de un servicio 100% personalizado. Dentro del servicio destaco algunas gestiones para llevar a cabo cada una de las operaciones Conocer el mercado, el sector Poner en precio la propiedad según el mercado. Promover la propiedad en distintos portales inmobiliarios, redes sociales y pagina web. Fotografía y folletería",
            buttonText: data.home && data.home.about && data.home.about.banner && data.home.about.banner.buttonText ? data.home.about.banner.buttonText : "Conózcanos"
          }
        },  
        services: {
          items: data.home && data.home.services && data.home.services.items.length ? data.home.services.items : [
            {
              title: "Compras",
              description: "Gestión en la evaluación del crédito hipotecaria en al menos 3 entidades financieras, contar con diversas estrategías para encontrar la propiedad ideal para el cloiente, se trabaja con canje para aumentar la posibilidad en la búsqueda del hogar."
            },
            {
              title: "Venta",
              description: "poner en precio la propiedad, recopilación de documentación necesarios para la operación, promoción de la propiedad en distintos portales inmobiliarios, pagina web, redes sociales (Instagram, Facebook, LinkedIn). Programar y realizar las visitas de potenciales clientes, selección de candidatos."
            },
            {
              title: "Arriendo",
              description: "Nuestro equipo gestiona el proceso de arriendo de propiedades de manera integral y profesional. Creamos fichas detalladas de las propiedades, fijamos precios competitivos basados en estudios de mercado, y promocionamos en diversos portales inmobiliarios. Nos encargamos de la fotografía y folletería, respondemos a preguntas e intereses de los potenciales arrendatarios, y programamos visitas a terreno. Realizamos una selección cuidadosa de candidatos, gestionamos la documentación y redactamos contratos de arriendo claros y precisos. Finalmente, proporcionamos seguimiento y reporte detallado de la operación, asegurando una experiencia sin contratiempos para todas las partes involucradas."
            }
          ]
        },  
        reviews: {
          items: data.home && data.home.reviews && data.home.reviews.items.length ? data.home.reviews.items : [
            {
              id: null,
              review: "Mi experiancia con NUVIS ha sido maravillosa, me entrego confianza del primer minuto, hizo un trabajo muy profesional, me ayudo en todo el proceso de la compra de mi casa, paso a paso, estoy muy agradecido de la gestión. Totalmente recomendable.",
              author: "Emilia"
            },
            {
              id: null,
              review: "Nuestra experiencia con Emely como corredora de bienes raíces ha sido maravillosa, ella es muy atenta, amable, tiene respuestas correctas a todo y siempre busca que ambas partes negociantes se sientan satisfechas y atendidas. Sin duda alguna la recomendamos 100% para sus gestiones de bienes raíces. Nosotros de ahora en adelante siempre trabajaremos con ella para nuestros negocios de bienes raíces.",
              author: "Fun Pick"
            }
          ]
        }                    
      },
      about:{
        hero: {
          background: data.about && data.about.hero && data.about.hero.background ? data.about.hero.background : require("../images/template-about-hero-background.jpg"),
          title: data.about && data.about.hero && data.about.hero.title ? data.about.hero.title : "Nueva Vista Gestión Inmobiliaria.  "
        },
        history: {
          background: data.about && data.about.history && data.about.history.background ? data.about.history.background : require("../images/template-about-history-background.jpg"),
          image1: require("../images/caso1/1.jpeg"),
          image2: require("../images/caso1/2.jpeg"),
          image3: require("../images/caso1/3.jpeg"),
          image4: require("../images/caso1/4.jpeg"),
          image5: require("../images/caso1/5.jpeg"),
          image6: require("../images/caso1/6.jpeg"),
          image7: require("../images/caso1/7.jpeg"),
          image8: require("../images/caso2/1.jpeg"),
          image9: require("../images/caso2/2.jpeg"),
          image10: require("../images/caso2/3.jpeg"),
          image11: require("../images/caso2/4.jpeg"),
          image12:require("../images/caso2/5.jpeg"),
          image13: require("../images/caso2/6.jpeg"),
          description: data.about && data.about.history && data.about.history.description ? data.about.history.description : "<p>Somos una nueva empresa, que brindará los servicios de gestión inmobiliaria en el sector de Villa Alemana. Orientada principalmente a la compraventa y arriendos de propiedades.</p>",
          title: data.about && data.about.history && data.about.history.title ? data.about.history.title : "Nuestra Historia"
        },
        description: {
          items: data.about && data.about.description && data.about.description.items.length ? data.about.description.items : [
            {
              title: "Misión",
              description: "Ser reconocida en el sector por entregar un excelente servicio.  "
            },
            {
              title: "Visión",
              description: "Estar contantemente mejorando la experiencia del cliente y acercarlos cada día más a conseguir su hogar ideal.  "
            }
          ]
        },
        stats: {
          proffesionals: data.about && data.about.stats && data.about.stats.proffesionals ? data.about.stats.proffesionals : 70,
          properties: data.about && data.about.stats && data.about.stats.properties ? data.about.stats.properties : 1000,
          years: data.about && data.about.stats && data.about.stats.years ? data.about.stats.years : 50,
          transactions: data.about && data.about.stats && data.about.stats.transactions ? data.about.stats.transactions : 500
        },
        team: {
          visible: true,
          items: data.about && data.about.team && data.about.team.items.length ? data.about.team.items : [
            {
              avatar: require("../images/template-team-member-1.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario1@example.com",
              fullName: "Usuario 1",
              phone: "+56 9 5555 5555"
            },
            {
              avatar: require("../images/template-team-member-2.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca.",
              email: "usuario2@example.com",
              fullName: "Usuario 2",
              phone: "+56 9 5555 5555"
            },
            {
              avatar: require("../images/template-team-member-3.jpg"),
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario3@example.com",
              fullName: "Usuario 3",
              phone: "+56 9 5555 5555"
            },
            {
              //avatar: require("../images/template-team-member-4.jpg"),
              avatar: "",
              cv: "Ingeniero Comercial, Master en Finanzas. Inversor inmobiliario, con 6 años de experiencia en Banca, Mesa de Dinero. 6 años en el corretaje de propiedades, especializado en el manejo de cartera de propiedades. ",
              email: "usuario4@example.com",
              fullName: "Usuario 4",
              phone: "+56 9 5555 5555"
            }                          
          ]
        },
        ubication: {
          title:  data.about && data.about.ubication && data.about.ubication.title ? data.about.ubication.title : "¿Necesitas vender, arrendar o comprar una propiedad? Somos tu socio perfecto"
        }
      },
      contact: {
        map: {
          title: data.contact && data.contact.map && data.contact.map.title ? data.contact.map.title : "Estamos en la Quinta Región",
          subTitle: data.contact && data.contact.map && data.contact.map.subTitle ? data.contact.map.subTitle : "234"
        },
        title: data.contact && data.contact.title ? data.contact.title : "¿Dudas? ¿Consultas? Estamos aquí para ayudarlo.",
        subTitle: data.contact && data.contact.subTitle ? data.contact.subTitle : "Envienos un mensaje y uno de nuestros asesores se pondra en contacto a la brevedad"        
      },
    }
  )
}