import styled from 'styled-components';

export default styled.span`
  color: ${props => props.light ? "#fff" : props.dark ? "#333333" : "white"};
  text-decoration: none;
  transition: color 250ms ease; /* Cambiado a 'color' en lugar de 'text-decoration' */
  margin-left: ${props => props.first ? "0" : "1rem"};
  font-size: 14px; /* Tamaño de fuente más pequeño */
  &:visited {
    color: ${props => props.light ? "#fff" : props.dark ? "#333333" : "white"};
  }
  &:hover {
    color: ${props => props.light ? "gray" : props.theme.primaryColor}; /* Cambiado a gris en hover */
    text-decoration: ${props => props.light && "underline"};
  }  
  @media(min-width: 2000px) {
    margin-left: ${props => props.first ? "0" : ".9rem"};
    color: ${props => props.light ? "rgba(255, 255, 255, .7)" : props.dark ? "#333333" : "white"};
    font-size: 17px; /* Tamaño de fuente más pequeño */
    &:hover {
      color: gray; /* Cambiado a gris en hover */
      text-decoration: none;
    }  
  }
`;
