// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-1-js": () => import("./../../../src/pages/index1.js" /* webpackChunkName: "component---src-pages-index-1-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-properties-js": () => import("./../../../src/pages/properties.js" /* webpackChunkName: "component---src-pages-properties-js" */),
  "component---src-pages-property-js": () => import("./../../../src/pages/property.js" /* webpackChunkName: "component---src-pages-property-js" */),
  "component---src-pages-quilpue-js": () => import("./../../../src/pages/quilpue.js" /* webpackChunkName: "component---src-pages-quilpue-js" */),
  "component---src-pages-valpa-js": () => import("./../../../src/pages/valpa.js" /* webpackChunkName: "component---src-pages-valpa-js" */),
  "component---src-pages-vende-js": () => import("./../../../src/pages/vende.js" /* webpackChunkName: "component---src-pages-vende-js" */),
  "component---src-pages-villa-js": () => import("./../../../src/pages/villa.js" /* webpackChunkName: "component---src-pages-villa-js" */),
  "component---src-pages-vina-js": () => import("./../../../src/pages/vina.js" /* webpackChunkName: "component---src-pages-vina-js" */)
}

